<template>
    <button
        data-comp="Button"
        v-on:click="emit('clicked')"
        :value="value"
        :type="type"
        :disabled="disabled === true || loading === true"
        :class="[disabled === true ? 'cursor-not-allowed' : 'cursor-pointer']"
    >
        <div v-if="icon && !loading">
            <i :class="['icon fa-solid', icon]"/>
        </div>

        <div v-if="loading">
            <LoadingIcon/>
        </div>

        <div v-if="value">
            {{ value }}
        </div>

        <template v-else>
            <slot/>
        </template>
    </button>
</template>

<script setup>
    import LoadingIcon from '@/Components/LoadingIcon.vue';

    const emit = defineEmits(['clicked']);

    defineProps({
        value: {type: String, required: false},
        type: {type: String, default: 'button'},
        icon: {type: String, required: false},
        disabled: {type: Boolean, default: false},
        loading: {type: Boolean, default: false},
    });
</script>

<style lang="scss" scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

    [data-comp="Button"] {
        @extend .theme-primary;
        @extend .size-large;
    }

    [data-comp="Button"][class*=theme-],
    [data-comp="Button"][class*=size-] {
        :deep([data-comp="Icon"]) {
            @apply w-5 h-5;
        }
    }

    [data-comp="Button"] {
        @apply inline-flex flex-row justify-center items-center gap-2 box-border font-heebo;
        @apply px-4 rounded;
        @apply text-body font-semibold whitespace-nowrap;
        @apply transition ease-in-out duration-200;
        @apply bg-none;
        @apply leading-4;
        @apply cursor-pointer;

        &.theme-primary {
            @apply bg-primary-500 text-white;
            @apply cursor-pointer;
        }

        &.theme-secondary {
            @apply bg-white text-gray-600 border border-solid border-gray-200 h-auto;
        }

        &.theme-tertiary {
            @apply bg-white text-primary-500 border border-solid border-gray-200 hover:bg-white hover:text-primary-500
            hover:border-gray-200 active:bg-white active:text-primary-500 active:border-gray-200 focus:bg-white
            focus:text-primary-500 focus:border-gray-200;
        }

        &.theme-error {
            @apply bg-error-500 text-white border border-solid border-error-500;
        }

        &.theme-dark {
            @apply bg-black text-white border border-solid border-black;
            @apply h-[3rem];
        }

        &.theme-lighter {
            @apply bg-gray-300 text-black border border-solid border-gray-300;
            @apply h-[3rem];
        }

        &.theme-outline {
            @apply bg-white text-primary-500 border border-solid border-primary-500;
        }

        &.theme-text {
            @apply text-primary-500;
            padding: 0;
            font-size: 1.1rem;
            font-weight: 400;
        }

        &.theme-none {
            @apply text-gray-400;
        }

        &.size-small {
            height: 2.75rem;
        }

        &.size-large {
            height: 3rem;
        }

        &.size-extra-large {
            @apply px-8;
            height: 3.5em;
            font-size: 120%;
        }

        &.size-auto {
            height: auto;
        }
    }

    [data-comp="Button"] {
        &.theme-primary:hover {
            @apply bg-primary-600;
        }
        &.theme-primary:focus {
            @apply bg-primary-700;
        }
        &.theme-primary:active {
            @apply bg-primary-700;
        }
        &.theme-primary:disabled {
            @apply bg-gray-300 cursor-not-allowed;
        }

        &.theme-secondary:hover {
            @apply bg-gray-50 text-gray-800 border border-solid border-gray-300 cursor-pointer;
        }
        &.theme-secondary:focus {
            @apply bg-gray-50 text-gray-800 border border-solid border-gray-400;
        }
        &.theme-secondary:active {
            @apply bg-gray-200 text-gray-800 border border-solid border-gray-400;
        }
        &.theme-secondary:disabled {
            @apply bg-white text-gray-400 border border-solid border-gray-300 cursor-not-allowed;
        }

        &.theme-error:hover {
            @apply bg-error-700 text-white border border-solid border-error-700 cursor-pointer;
        }
        &.theme-error:focus {
            @apply bg-error-500 text-white border border-solid border-error-500;
        }
        &.theme-error:active {
            @apply bg-error-800 text-white border border-solid border-error-800;
        }
        &.theme-error:disabled {
            @apply bg-error-500 text-white border border-solid border-error-500 cursor-not-allowed;
        }

        &.theme-outline:hover {
            @apply bg-white text-primary-600 border border-solid border-primary-600 cursor-pointer;
        }
        &.theme-outline:focus {
            @apply bg-white text-primary-700 border border-solid border-primary-700;
        }
        &.theme-outline:active {
            @apply bg-white text-primary-700 border border-solid border-primary-700;
        }
        &.theme-outline:disabled {
            @apply bg-white text-gray-400 border border-solid border-gray-300 cursor-not-allowed;
        }

        &.theme-dark:hover {
            @apply bg-gray-700 text-white border border-solid border-gray-700 cursor-pointer;
        }
        &.theme-dark:focus {
            @apply bg-gray-700 text-white border border-solid border-gray-800;
        }
        &.theme-dark:active {
            @apply bg-gray-800 text-white border border-solid border-gray-800;
        }
        &.theme-dark:disabled {
            @apply bg-gray-800 text-white border border-solid border-gray-800 cursor-not-allowed;
        }

        &.theme-lighter:hover {
            @apply bg-gray-200 text-black border border-solid border-gray-200 cursor-pointer;
        }
        &.theme-lighter:focus {
            @apply bg-gray-300 text-black border border-solid border-gray-300;
        }
        &.theme-lighter:active {
            @apply text-black border border-solid bg-gray-200;
        }
        &.theme-lighter:disabled {
            @apply text-black border border-solid bg-gray-400 cursor-not-allowed;
        }
    }
</style>
